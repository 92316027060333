import { createContext, useReducer, useEffect, useState } from "react";

export const AuthContext = createContext();

export const authReducer = (state, action) => {
	switch (action.type) {
		case "LOGIN":
			return { user: action.payload, isAuthenticated: true };
		case "LOGOUT":
			return { user: null, isAuthenticated: false };
		default:
			return state;
	}
};

export const AuthContextProvider = ({ children }) => {
	const [isAuthenticated, setisAuthenticated] = useState(false);
	const [state, dispatch] = useReducer(authReducer, {
		user: null,
		isAuthenticated: false,
	});
	const user = JSON.parse(localStorage.getItem("user"));

	useEffect(() => {
		if (user) {
			setisAuthenticated(true);
			dispatch({ type: "LOGIN", payload: user });
		}
		const handleStorage = (event) => {
			if (event.key === "user" && event.newValue === null) {
				window.location.href = "/login";
			}
		};
		window.addEventListener("storage", handleStorage);

		return () => {
			window.removeEventListener("storage", handleStorage);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// console.log("AuthContext state:", state);

	return (
		<AuthContext.Provider value={{ ...state, dispatch, isAuthenticated }}>
			{children}
		</AuthContext.Provider>
	);
};
