import React, { useState, useEffect } from 'react'
import { ProgressBar, Button } from 'react-bootstrap'

import envDotJson from '../assets/installation/env.json'
import defaultValues from '../assets/installation/defaultValues.json'

import EnvVariableForm from '../components/EnvVariableForm'
import Title from '../components/Title'
import CustomModal from '../components/CustomModal.jsx'

import ProductService from '../services/product.service'
import { saveFileText } from '../services/downloadUtils'

const EnvFile = () => {
    let [validVariables, setValidVariables] = useState(0)
    let [invalidVariables, setInvalidVariables] = useState(0)
    let [mandatoryVariables, setMandatoryVariables] = useState([])
    let [dotEnv, setDotEnv] = useState({})
    let [showModal, setShowModal] = useState(false)

    const getMandatoryVariables = (modulesToInstall) => {
        let userInputVariables = envDotJson.filter((env) => env.userInput)
        let variableToFill = userInputVariables.filter((v) => ((v.products && Object.keys(v.products).some(module => modulesToInstall.includes(module))) || !v.products) && !v.installation_bool && !v.online)
        return variableToFill.filter((v) => !Object.keys(defaultValues).includes(v.variable_name))
    }

    const downloadEnvFile = () => {
        saveFileText(Object.keys(dotEnv).map(key => `${key}=${dotEnv[key]}`).join('\n'))
    }

    const getInstallationBool = (modulesToInstall) => {
        let userInputVariables = envDotJson.filter((env) => env.userInput)
        return userInputVariables.filter((v) => v.installation_bool).filter(v => modulesToInstall.includes(v.variable_name.toLowerCase())).map(v => v.variable_name)
    }

    const handleValidity = (valid = true, adding = true) => {
        if (valid) {
            setValidVariables(adding ? validVariables + 1 : validVariables - 1)
        }
        else {
            setInvalidVariables(adding ? invalidVariables + 1 : invalidVariables - 1)
        }
    }

    const setVariable = (obj) => {
        setDotEnv(Object.assign(dotEnv, obj))
    }

    useEffect(() => {
        ProductService.getProductBoard().then((response) => {
            let variablesToFill = getMandatoryVariables(response.data.modules.map((m) => m.includes('scan-') ? 'scan' : m))
            setMandatoryVariables(variablesToFill)

            setDotEnv(Object.assign(Object.fromEntries(getInstallationBool(response.data.modules.map((m) => m.endsWith('-server') ? m.replace('-server', '') : m)).map(k => [k, "TRUE"])), defaultValues))
        })
    }, [])

    return (
        <>{showModal &&
            <CustomModal show={showModal}
                handleContinue={() => {
                    setShowModal(false)
                    downloadEnvFile()
                }}
                handleClose={() => setShowModal(false)}
                title={`Formulaire incomplet`}
                text={`Tous les champs nécessaires ne sont pas remplis ou pas remplis correctement. Le .env téléchargé ne contiendra que les valeurs valides remplies. `}
                continueText={`Télécharger quand même`}
            />}
            <div className="m-3">
                <Title title={"Génération du .env"} />
                {
                    mandatoryVariables.map(variable =>
                        <EnvVariableForm
                            key={variable.variable_name}
                            variable={variable}
                            handleValidity={handleValidity}
                            setVariable={setVariable}
                            swarmMode={dotEnv['SWARM_MODE'] != 'false'}
                        ></EnvVariableForm>
                    )
                }
                <div style={{ height: "60px" }}></div>
            </div>
            <div className="envFileFooter p-2 border-top">
                <table width="100%"><tr><td>
                    <ProgressBar>
                        <ProgressBar variant="success" now={(validVariables / (dotEnv['SWARM_MODE'] == 'false' ? (mandatoryVariables.length - 1) : mandatoryVariables.length)) * 100} key={1} />
                        <ProgressBar variant="danger" now={(invalidVariables / (dotEnv['SWARM_MODE'] == 'false' ? (mandatoryVariables.length - 1) : mandatoryVariables.length)) * 100} key={2} />
                    </ProgressBar>
                </td>
                    <td width="300px" style={{
                        textAlign: "center"
                    }}>
                        <Button
                            variant='primary'
                            style={{ width: "200px" }}
                            onClick={() => {
                                if (validVariables == (dotEnv['SWARM_MODE'] == 'false' ? (mandatoryVariables.length - 1) : mandatoryVariables.length)) {
                                    downloadEnvFile()
                                }
                                else {
                                    setShowModal(true)
                                }
                            }}>
                            Télécharger le .env
                        </Button>
                    </td>
                </tr></table>

            </div>
        </>
    )
}

export default EnvFile