import React, { useState, useEffect } from 'react'
import { Accordion } from 'react-bootstrap'

import Title from '../components/Title'

import ProductService from '../services/product.service'

import LtrObject from '../components/LtrObject.jsx'
import DownloadErrorToast from '../components/DownloadErrorToast.jsx'

const LtrPage = () => {
    const [ltrVersions, setLtrVersions] = useState(null)
    const [availableLtr, setAvailableLtr] = useState(null)
    const [downloadError, setDownloadError] = useState(false)
    const [dependenciesVersion, setDependenciesVersion] = useState(false)

    let showToast = () => {
        setDownloadError(true)
        setTimeout(() => {
            setDownloadError(false)
        }, "5000")
    }

    useEffect(() => {
        ProductService.getProductsVerions().then((response) => {
            let versions = response.data
            let modules = {}
            let ltr = {}
            let dependencies = {}
            Object.keys(versions).forEach((m) => {
                modules[m] = {}
                Object.keys(versions[m].versions).filter(v => versions[m].versions[v].ltr && versions[m].versions[v].ltr.length > 0).forEach((ltrV => {
                    modules[m][ltrV] = versions[m].versions[ltrV]
                    versions[m].versions[ltrV].ltr.forEach((l) => {
                        if (ltr[l]) {
                            ltr[l] = ltr[l] + 1
                        }
                        else {
                            ltr[l] = 1
                        }
                    })
                }))
                if (versions[m].dependency){
                    dependencies[m] = versions[m]
                }
            })
            setLtrVersions(modules)
            setDependenciesVersion(dependencies)
            setAvailableLtr(Object.keys(ltr).filter((v) => ltr[v] == Object.values(ltr).reduce((a, b) => Math.max(a, b), -Infinity)).sort().reverse())
        })
    }, [])

    return (

        <div className="m-3">
            <DownloadErrorToast downloadError={downloadError} setDownloadError={setDownloadError}></DownloadErrorToast>

            <Title title={"LTR disponibles"} />
            {availableLtr && <Accordion defaultActiveKey="0" >
                {availableLtr.map((ltr, i) =>
                    <Accordion.Item key={i} eventKey={i.toString()}>
                        <LtrObject ltr={ltr} ltrVersions={ltrVersions} setDownloadError={showToast} dependenciesVersion={dependenciesVersion}></LtrObject>
                    </Accordion.Item>
                )}
            </Accordion>}
        </div>
    )
}

export default LtrPage