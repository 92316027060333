import React, { useState } from "react"
import { Form } from 'react-bootstrap'


const EnvVariableForm = ({ variable, handleValidity, setVariable, swarmMode }) => {
    let [value, setValue] = useState('')
    let [valid, setValid] = useState(true)

    const regexCheck = (regex, value) => {
        let rexp = new RegExp(regex)
        return rexp.test(value)
    }

    return <>{ !(!swarmMode && variable.mode == 'swarm') &&
        <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>{variable.description.fr}</Form.Label>
        <Form.Control placeholder={`Remplir la variable ${variable.variable_name}`}
            style={{
                borderColor: valid ? "grey" : "red"
            }}
            value={value}
            onChange={(event) => {
                let previousLength = value.length
                let previousValidity = valid

                setValue(event.target.value)
                if (event.target.value.length == 0) {
                    handleValidity(previousValidity, false)
                    setValid(true)
                }
                else {
                    let isValid = regexCheck(variable.regex, event.target.value)
                    if (previousLength == 0) {
                        handleValidity(isValid, true)
                    }
                    else {
                        if (previousValidity != isValid) {
                            handleValidity(isValid, true)
                            handleValidity(!isValid, false)
                        }
                    }
                    if (isValid) {
                        let obj = {}
                        obj[variable.variable_name] = event.target.value
                        setVariable(obj)
                    }
                    setValid(isValid)
                }
            }} />
    </Form.Group>
    }</>
}

export default EnvVariableForm