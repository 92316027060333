import React, { lazy, Suspense } from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import { useAuthContext } from "../hooks/useAuthContext"
import EnvFile from "../pages/EnvFile"
import LtrPage from "../pages/LtrPage"
import Spinner from 'react-bootstrap/Spinner'

// IMPORT PAGES
const HomePage = lazy(() => import("../pages/HomePage.jsx"))
const DownloadPage = lazy(() => import("../pages/DownloadPage.jsx"))
const Products = lazy(() => import("../pages/Products.jsx"))
const Documentation = lazy(() => import("../pages/Documentation.jsx"))
const GroupConfiguration = lazy(() => import("../pages/GroupConfiguration.jsx"))

const OpenRoutes = () => {
    const LoginPage = lazy(() => import("../pages/LoginPage.jsx"))

    const { user } = useAuthContext()

    const expiration = new Date(localStorage.getItem("key_expiration"))
    const now = new Date()

    if (now > expiration) {
        localStorage.removeItem("user")
        localStorage.removeItem("key_expiration")
    }

    return (
        <Suspense fallback={<div className="loadingSpinner"><Spinner animation="grow" /> <Spinner animation="grow" /> <Spinner animation="grow" /></div>}>
            <Routes>
                <Route
                    exact
                    index
                    path="/"
                    element={<Navigate to={"/login"} />}
                />
                <Route exact index path="/login" element={<LoginPage />} />

                {user && (
                    <>
                        <Route path="/ltr" element={<LtrPage />} />
                        <Route path="/module" element={<HomePage />} />
                        <Route path="/parameters" element={<EnvFile />} />
                        <Route path="/products" element={<Products />} />
                        <Route path="/documentation" element={<Documentation/>} />
                        <Route path="/configuration" element={<GroupConfiguration/>} />
                        <Route
                            path="/module/:productName"
                            element={<DownloadPage />}
                        />
                    </>
                )}
                <Route path="*" element={<LoginPage />} />
            </Routes>
        </Suspense>
    )
}

export default OpenRoutes
