import React, { useState, useEffect } from 'react'
import { Button, Spinner, Badge } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'

import { FaDownload } from 'react-icons/fa'
import Form from 'react-bootstrap/Form'

import { downloadImage } from '../services/utils'

const LtrSpecificProduct = ({ module, moduleConfiguration, setCurrentVersion, setDownloadError }) => {
    const location = useLocation()
    const [downloading, setDownloading] = useState(false)
    const [versionName, setVersionName] = useState(null)
    const [versionSize, setVersionSize] = useState(null)
    const [versionExtension, setVersionExtension] = useState(null)
    const [possibleVersionValues, setPossibleVersionValues] = useState(null)
    const [selectedVersion, setSelectedVersion] = useState(null)

    useEffect(() => {
        setVersionName(moduleConfiguration.currentVersion)
        setVersionSize(moduleConfiguration.allSize && moduleConfiguration.allSize[moduleConfiguration.currentVersion] ? moduleConfiguration.allSize[moduleConfiguration.currentVersion].size : moduleConfiguration.size)
        setVersionExtension(moduleConfiguration.extension)
        setPossibleVersionValues(moduleConfiguration.allAvailableVersion)
        setSelectedVersion(moduleConfiguration.currentVersion)
    }, [moduleConfiguration])

    function changeVersionToDownload(version){
        setSelectedVersion(version)
        setCurrentVersion(module, version)
        setVersionSize(moduleConfiguration.allSize && moduleConfiguration.allSize[version] ? moduleConfiguration.allSize[version].size : null)
    }
    return (
        <>
            <tr>
                <td>
                    <table widht="100%">
                        <tr><td>{module} -</td><td>
                            {!(possibleVersionValues && possibleVersionValues.length > 1) ? <>{versionName}</> : <Form.Select size="sm" style={{ width: "130px" }}
                            value={selectedVersion}
                            onChange={(e) => {
                                changeVersionToDownload(e.target.value)
                            }}>
                                {
                                    possibleVersionValues.map(v => <option>{v}</option>)
                                }
                            </Form.Select>}</td><td>{versionSize && <Badge bg="secondary" className='m-2 mt-0 mb-0'>{Math.round(versionSize * 0.000095367432) / 100} MB</Badge>}</td>
                        </tr>
                    </table>
                </td>
                <td style={{ 'textAlign': 'right' }}><Button
                    variant="primary"
                    className="pt-0 pb-1 pr-1 pl-1"
                    disabled={downloading}
                    onClick={() => {
                        downloadImage(module, versionName, versionExtension, location, document, setDownloading, () => {
                            setDownloading(false)
                            setDownloadError()
                        })
                    }}> {!downloading ? <FaDownload size={12} /> : <Spinner animation="border" size="sm" />}
                </Button>
                </td></tr>
        </>
    )
}

export default LtrSpecificProduct