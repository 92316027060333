import ProductService from "../services/product.service"

function createDownload(response, downloadName) {
    var link = document.createElement("a")
    link.href = URL.createObjectURL(new Blob([response.data]))
    link.download = downloadName
    link.click()
}

export async function downloadImage(product, version, extension, currentLocation, document, setDownload, errorFunction = () => { }) {
    setDownload(true)
    await ProductService.getModuleDownload(product, version, extension)
        .then((response) => {
            if (!currentLocation || (window.location.pathname == currentLocation.pathname)) {
                createDownload(response, `${product}-${version}.${extension}`)
                setDownload(false)
            }
        })
        .catch((error) => {
            errorFunction()
        })
}

export async function downloadDocumentation(path, downloadName) {
    await ProductService.getDocumentationDownload(path)
        .then((response) => {
            createDownload(response, downloadName)
        })
        .catch((error) => {
            
        })
}