import React from 'react'
import { Toast } from 'react-bootstrap'

const DownloadErrorToast = ({ downloadError, setDownloadError }) => {

    return <Toast show={downloadError}
        bg="light"
        onClose={() => {
            setDownloadError(!downloadError)
        }}
        style={{ position: 'fixed', zIndex: 99, top: "80px", right: 10 }}>
        <Toast.Header>
            <strong className="me-auto">Erreur lors du téléchargement</strong>
        </Toast.Header>
        <Toast.Body>Veuillez réessayer ultérieurement. </Toast.Body>
    </Toast>
}

export default DownloadErrorToast