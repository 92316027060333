import JsZip from 'jszip'
import FileSaver from 'file-saver'
import Promise from 'bluebird'

import ProductService from "./product.service"

const downloadByGroup = (modules, files_per_group = 5) => {
    return Promise.map(
        modules,
        async module => {
            let { product, version, extension } = module
            try {
                let res = await ProductService.getModuleDownload(product, version, extension)
                return { blob: new Blob([res.data]), name: `${product}-${version}.${extension}` }
            }
            catch (e) {
                throw new Error('Unexpected error occured')
            }
        },
        { concurrency: files_per_group }
    )
}

const exportZip = (blobs, ltr, setDownloading) => {
    const zip = JsZip()
    blobs.forEach((b) => {
        let { blob, name } = b
        zip.file(name, blob)
    })
    zip.generateAsync({ type: 'blob' }).then(zipFile => {
        const fileName = `${ltr}.zip`
        setDownloading(false)
        return FileSaver.saveAs(zipFile, fileName)
    })
}

export async function downloadAndZip(ltr, versions, setDownloading, setDownloadError) {
    setDownloading(true)
    try {
        await downloadByGroup(versions, 5).then((res) => exportZip(res, ltr, setDownloading))
    }
    catch (e) {
        setDownloading(false)
        setDownloadError()
    }
}

export function saveFileText(content) {
    var blob = new Blob([content], {type: "text/plain;charset=utf-8"})
    return FileSaver.saveAs(blob, `.env`)
}