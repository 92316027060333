import React from 'react'
import { Modal, Button } from 'react-bootstrap'

const CustomModal = ({ handleClose, handleContinue, title, text, show, continueText }) => {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {text}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Annuler
                </Button>
                <Button variant="primary" onClick={handleContinue}>{continueText}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CustomModal