import { lazy } from "react"
import { BrowserRouter as Router } from "react-router-dom"
import * as moment from 'moment'
import 'moment/locale/fr'

import RoutesComponent from "./routes/RoutesComponent"

import './assets/css/global.scss'

moment.locale('fr')

const NavbarComponent = lazy(() => import("./components/NavbarComponent.jsx"))

function App() {

    return (
        <>
            <Router>
                <NavbarComponent />
                <div> <RoutesComponent /></div>
            </Router>
        </>
    )
}

export default App
