import axios from "axios"
import authHeader from "./auth-header"

let apiUrl = `${process.env.REACT_APP_API_URL}/api`

const getProducts = () => {
	const config = {
		method: "get",
		url: `${apiUrl}/product`,
		headers: authHeader(),
	}

	return axios(config)
}

const getDocumentations = () => {
	const config = {
		method: "get",
		url: `${apiUrl}/documentation/all`,
		headers: authHeader(),
	}

	return axios(config)
}

const getGroup = () => {
	const config = {
		method: "get",
		url: `${apiUrl}/group/mine`,
		headers: authHeader(),
	}

	return axios(config)
}

const getAllProducts = () => {
	const config = {
		method: "get",
		url: `${apiUrl}/product/all`,
		headers: authHeader(),
	}

	return axios(config)
}

const getProductBoard = () => {
	const config = {
		method: "get",
		url: `${apiUrl}/module`,
		headers: authHeader(),
	}

	return axios(config)
}

const getProductsVerions = () => {
	const config = {
		method: "get",
		url: `${apiUrl}/module/versions`,
		headers: authHeader(),
	}

	return axios(config)
}

const getProductVersions = (productName) => {
	const config = {
		method: "get",
		url: `${apiUrl}/module/${productName}`,
		headers: authHeader(),
	}
	return axios(config)
}

const getModuleDownload = (productQuery, versionQuery, extensionQuery) => {
	const config = {
		method: "get",
		url: `${apiUrl}/module/download`,
		headers: authHeader(),
		params: {
			name: productQuery,
			version: versionQuery,
			extension: extensionQuery,
		},
		responseType: "blob",
	}

	return axios(config)
}

const getDocumentationDownload = (path) => {
	const config = {
		method: "get",
		url: `${apiUrl}/documentation/download`,
		headers: authHeader(),
		params: {
			name : path
		},
		responseType: "blob",
	}
	return axios(config)
}

const ProductService = {
	getProductVersions,
	getProductBoard,
	getModuleDownload,
	getProductsVerions,
	getProducts,
	getAllProducts,
	getDocumentations,
	getDocumentationDownload,
	getGroup
}

export default ProductService
