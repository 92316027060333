import React, { useEffect, useState } from 'react'
import { Accordion, Button, Table, Spinner, Alert } from 'react-bootstrap'
import ReactMarkdown from 'react-markdown'

import { FaDownload } from 'react-icons/fa'

import { downloadAndZip } from '../services/downloadUtils'

import LtrSpecificProduct from './LtrSpecificProduct'

import ltrDescription from '../assets/ltr/ltr.json'

const LtrObject = ({ ltr, ltrVersions, setDownloadError, dependenciesVersion }) => {
    const [downloading, setDownloading] = useState(false)
    const [versionConfiguration, setVersionConfiguration] = useState({})

    let setCurrentVersion = (module, version) => {
        let config = versionConfiguration
        config[module].currentVersion = version
        setVersionConfiguration(config)
    }

    let getDependencies = (complianceObject) => {
        return Object.keys(complianceObject).filter(comp => Object.keys(dependenciesVersion).includes(comp))
    }

    useEffect(() => {
        let versionConfigurationToSet = {}

        Object.keys(ltrVersions).sort().forEach((module) => {// For every module in ltr
            Object.keys(ltrVersions[module]).forEach((v) => {
                if (ltrVersions[module][v].ltr.includes(ltr)) {
                    versionConfigurationToSet[module] = {
                        "size": ltrVersions[module][v].size,
                        "extension": ltrVersions[module][v].extension,
                        "currentVersion": v
                    }

                    if (ltrVersions[module][v].compliance && getDependencies(ltrVersions[module][v].compliance).length > 0) {
                        getDependencies(ltrVersions[module][v].compliance).forEach((comp) => {
                            if (versionConfigurationToSet[comp]) {
                                versionConfigurationToSet[comp].allAvailableVersion = versionConfigurationToSet[comp].allAvailableVersion.filter(value => ltrVersions[module][v].compliance[comp].includes(value))
                                versionConfigurationToSet[comp].currentVersion = versionConfigurationToSet[comp].allAvailableVersion[versionConfigurationToSet[comp].allAvailableVersion.length - 1]
                            }
                            else {
                                versionConfigurationToSet[comp] = {}
                                versionConfigurationToSet[comp].allAvailableVersion = ltrVersions[module][v].compliance[comp]
                                versionConfigurationToSet[comp].currentVersion = ltrVersions[module][v].compliance[comp][ltrVersions[module][v].compliance[comp].length - 1]
                                versionConfigurationToSet[comp].extension = 'zip'
                                versionConfigurationToSet[comp].allSize = dependenciesVersion[comp].versions
                            }

                        })
                    }
                }
            })
        })

        setVersionConfiguration(versionConfigurationToSet)
    }, [])

    function getVersionsToDownload(ltr) {
        let versions = []
        Object.keys(versionConfiguration).filter(m => versionConfiguration[m]).forEach((m) => {
            let version = versionConfiguration[m]
            versions.push({
                product: m,
                version: version.currentVersion,
                extension: version.extension
            })
        })
        return versions
    }

    return (
        <>
            <Accordion.Header>LTR version {ltr} <Button variant="light" onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()

                downloadAndZip(`isogeo-ltr-${ltr}`, getVersionsToDownload(ltr), setDownloading, setDownloadError)
            }}> {!downloading ? <FaDownload /> : <Spinner animation="border" size="sm" />}</Button></Accordion.Header>
            <Accordion.Body className="p-0 m-0">
                <Alert key={`altert-${ltr}`} variant='light' className='border-bottom pb-0 mb-0'>
                    <>{ltrDescription && ltrDescription[ltr] && ltrDescription[ltr].fr && <ReactMarkdown>{ltrDescription[ltr].fr}</ReactMarkdown>}</>
                </Alert>
                <Table hover className='mb-0'>
                    <tbody>
                        {
                            versionConfiguration && Object.keys(versionConfiguration).sort().filter(m => versionConfiguration[m]).map(m => {
                                return <LtrSpecificProduct key={`${ltr}-${m}`} module={m} setDownloadError={setDownloadError} moduleConfiguration={versionConfiguration[m]} setCurrentVersion={setCurrentVersion}></LtrSpecificProduct>
                            })
                        }
                    </tbody>
                </Table>
            </Accordion.Body>
        </>
    )
}

export default LtrObject